import React, {useEffect} from 'react'
import { createPortal } from "react-dom"
import modal from './Modal.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'

export const Modal = ({onClose}) => {

    const modalRoot = document.getElementById("modals");

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={modal.modal} onClick={evt => evt.stopPropagation()}>
                <button className={modal.button} onClick={onClose} aria-label='закрыть меню'/>
                <h2 className={modal.title}>СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ <span>10%</span> СКИДКА НА ПЕРВЫЙ ВИЗИТ</h2>
                <p className={modal.text}>Запишитесь на удобную дату и время прямо сейчас!</p>
                <a className={modal.link}  href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};